import {createSlice} from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
    name: 'global',
    initialState: {
        user: {
            id: 0,
            email: "",
            authenticated: null,
            subscribed: null
        }
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        }
    }
})

export const {
    setUser,
} = GlobalSlice.actions

export default GlobalSlice.reducer