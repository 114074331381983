import {configureStore} from "@reduxjs/toolkit";
import GlobalSlice from './slice'
import GirlSidebarSlice from './../src/components/pages/Girls/Sidebar/slice'
import PhotoSidebarSlice from './../src/components/pages/Photos/Sidebar/slice'

export default configureStore({
    reducer: {
        global: GlobalSlice,
        girlSidebar: GirlSidebarSlice,
        photoSidebar: PhotoSidebarSlice,
    }
})