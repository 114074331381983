import React, {useState} from 'react'
import {JSC_USER_NAME} from '../../../constants'
import {Link} from 'react-router-dom'

import Box from '@mui/material/Box'

import PersonIcon from '@mui/icons-material/Person'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuIcon from '@mui/icons-material/Menu'
import Grow from '@mui/material/Grow'

import MenuItem from '@mui/material/MenuItem'
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material'

import SearchBar from '../../widgets/SearchBar'

import logo from '../../../assets/images/just-sexy-celebs-logo.png'

import LoginPopper from '../../auth/Login/LoginPopper'
import IconButton from '@mui/material/IconButton'


function Header({router, searchfilter}) {
    const [loginAnchor, setLoginAnchor] = useState(null)
    const [open, setOpen] = React.useState(false);
    const username = localStorage.getItem(JSC_USER_NAME)
    const accountLabel = username ? username : 'Login'

    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleLoginClick = event => {
        setLoginAnchor(loginAnchor ? null : event.currentTarget)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    }

    const handleNavigation = (event) => {

    }

    return (
        <>
            {/*<LoginPopper loginAnchor={loginAnchor} handleClose={handleLoginClick} username={username} router={router}/>*/}
            <AppBar color={'inherit'} position={'sticky'}>
                <Toolbar sx={{justifyContent: {xs: 'space-between'}}}>
                    <IconButton edge={`start`} aria-label={`menu`}>
                        <Link to={`/`}><img src={logo} alt={`Just Sexy Celebs`} style={{width: '100px'}}/></Link>
                    </IconButton>
                    <Box component={`nav`}>
                        <Button component={Link} to={`/girls`}>
                            Girls
                        </Button>
                        <Button component={Link} to={`/photos`}>
                            Photos
                        </Button>
                        <Button component={Link} to={`/videos`}>
                            Videos
                        </Button>
                        <Button component={Link} to={`/sources`}>
                            Movies & TV
                        </Button>
                    </Box>
                    <Box component={`nav`}>
                        <ListItem onClick={handleLoginClick}>
                            <ListItemIcon>
                                <PersonIcon/>
                            </ListItemIcon>
                            <ListItemText primary={accountLabel}/>
                        </ListItem>
                    </Box>
                    <List component={`nav`}>
                        <ListItem>
                            <SearchBar searchfilter={searchfilter}/>
                        </ListItem>
                    </List>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        sx={{display:{xs: 'block', md: 'none'}}}
                    >
                        <MenuIcon/>
                    </Button>
                    {/*<Popper open={open}  anchorEl={anchorRef.current} role={undefined} transition disablePortal>*/}
                    {/*    {({TransitionProps, placement}) => (*/}
                    {/*        <Grow*/}
                    {/*            {...TransitionProps}*/}
                    {/*            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}*/}
                    {/*        >*/}
                    {/*            <Paper>*/}
                    {/*                <ClickAwayListener onClickAway={handleClose}>*/}
                    {/*                    <MenuList autoFocusItem={open} id="menu-list-grow">*/}
                    {/*                        <MenuItem onClick={handleClose} component={Link}*/}
                    {/*                                  to={`/girls`}>Girls</MenuItem>*/}
                    {/*                        <MenuItem onClick={handleClose} component={Link}*/}
                    {/*                                  to={`/photos`}>Photos</MenuItem>*/}
                    {/*                        <MenuItem onClick={handleClose} component={Link}*/}
                    {/*                                  to={`/videos`}>Videos</MenuItem>*/}
                    {/*                        <MenuItem onClick={handleClose} component={Link} to={`/sources`}>Movies &*/}
                    {/*                            TV</MenuItem>*/}
                    {/*                    </MenuList>*/}
                    {/*                </ClickAwayListener>*/}
                    {/*            </Paper>*/}
                    {/*        </Grow>*/}
                    {/*    )}*/}
                    {/*</Popper>*/}
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header
