import React from 'react'
import {Link} from 'react-router-dom'


// const footerStyle = makeStyles({
//   footerStyle:{
//     textAlign: 'center',
//     width: '100%'
//   }
// })

function Footer() {
  return(
    <footer id="footer">
      <div className="footer_credit">
            <div className="content">
              <div id="copyright">
                <p>Copyright &copy; 2002-2020, Just Sexy Celebs |
                  <Link to={'/privacy'}>Privacy Policy</Link> |
                  <Link to={'/terms-of-use'}>Terms of Use</Link>
                </p>
              </div>
            </div>
      </div>
    </footer>
  )
}

export default Footer
