import React, {lazy, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import Box from '@mui/material/Box'
import CircularProgress from "@mui/material/CircularProgress"

//photos
//girls
//girl/:girlid
//videos
//video/:id
//account
//sources
//members
//plans


function AppRoutes() {

    const FallBack = () => (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "400px",
            }}
            m="auto"
        >
            <CircularProgress/>
        </Box>
    )

    const Home = (
        lazy(() => (
            import('./components/pages/Home')
        ))
    )

    const Girl = (
        lazy(() => (
            import('./components/pages/Girl')
        ))
    )

    const Girls = (
        lazy(() => (
            import('./components/pages/Girls')
        ))
    )

    const GirlAlphabet = (
        lazy(() => (
            import('./components/pages/GirlAlphabet')
        ))
    )

    const Photos = (
        lazy(() => (
            import('./components/pages/Photos')
        ))
    )

    const Videos = (
        lazy(() => (
            import('./components/pages/Videos')
        ))
    )

    const Video = (
        lazy(() => (
            import('./components/pages/Video')
        ))
    )

    const Sources = (
        lazy(() => (
            import('./components/pages/Sources')
        ))
    )



    return (
        <Suspense fallback={<FallBack />}>
            <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/girl/:girlId' element={<Girl/>}/>
                <Route path='/girls' element={<Girls/>}/>
                <Route path='/girls/:letter' element={<GirlAlphabet/>}/>
                <Route path='/photos' element={<Photos/>}/>
                <Route path='/sources' element={<Sources/>}/>
                <Route path='/videos' element={<Videos/>}/>
                <Route path='/video/:videoId' element={<Video/>}/>
            </Routes>
        </Suspense>
    )

}

export default AppRoutes