import React from 'react'

function SearchBar() {
    return(
        <>
            <div>Search Bar</div>
        </>
    )
}

export default SearchBar