import './assets/css/App.css';

import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    from,
} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"
import {AUTH_TOKEN} from "./constants"
import {ThemeProvider} from '@mui/material/styles'
import {theme} from './theme'
import Layout from "./components/global/Layout"
// import RefreshToken from "./components/auth/RefreshToken"

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({message, locations, path}) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        )

    if (networkError) console.error(`[Network error]: ${networkError}`)
})


const standardLink = new HttpLink({uri: process.env.REACT_APP_GRAPHQL_URL})
const authLink = setContext((_, {headers}) => {
    const token = sessionStorage.getItem(AUTH_TOKEN) ? sessionStorage.getItem(AUTH_TOKEN) : null
    return {
        headers: {
            ...headers,
            ...(token) && {Authorization: `Bearer ${token}`},
        },
    }
})


const link = from([errorLink, authLink, standardLink])

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    link,
})


function App() {
  return (
    <>
      <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
              <Layout />
          </ThemeProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
