import {createSlice} from "@reduxjs/toolkit"


const PhotoSidebarSlice = createSlice({
    name: 'photoSidebar',
    initialState: {
        drawerOpen: false,
        drawerItems: {
            clothing: true,
            exposure: true,
        }
    },
    reducers: {
        setDrawerOpen: (state, action) => {
            state.drawerOpen = action.payload
        },
        setDrawerItem: (state, action) => {
            state.drawerItems = {
                ...state.drawerItems,
                [action.payload.item]: !action.payload.value
            }
        }
    }
})

export const {
    setDrawerOpen,
    setDrawerItem
} = PhotoSidebarSlice.actions

export default PhotoSidebarSlice.reducer