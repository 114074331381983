import {createSlice} from "@reduxjs/toolkit"

const GirlSidebarSlice = createSlice({
    name: 'girlSidebar',
    initialState: {
        drawerOpen: false,
        drawerItems: {
            hairColor: true,
            profession: true,
            bodyType: true,
            breastSize: true,
            ethnicity: true,
        }
    },
    reducers: {
        setDrawerOpen: (state, action) => {
            state.drawerOpen = action.payload
        },
        setDrawerItem: (state, action) => {
            state.drawerItems = {
                ...state.drawerItems,
                [action.payload.item]: !action.payload.value
            }
        }
    }
})

export const {
    setDrawerOpen,
    setDrawerItem
} = GirlSidebarSlice.actions

export default GirlSidebarSlice.reducer